<div id="divTop" class="clsTop">
    <table>
        <tr>
            <td>
                <div class="clsTopLogo"></div>            
            </td>
            <td>&nbsp;</td>
            <td style="position: relative;">
                <div class="clsTopMarca">HIELO ICEMAN</div>  
                <div class="clsTopSlogan">keep it cool !</div> 
            </td>
        </tr>
    </table>
    <div id="divTopContacto" class="clsTopTel"><div class="clsImgTelTop"></div>55 4144 7325</div>
</div>

<div id="divContenido" class="clsContenido">
    <div id="divEspacioVacioArriba" style="height: 200px;"></div>

    <table style="width: 100%;">
        <tr>
            <td  class="clsContenidoBolsas clsBordeImgIzq" >
                <div> </div>
            </td>
            <td>&nbsp; &nbsp;</td>
            <td>
                <h1>Bolsas de hielo</h1>
                <br>
                Bolsa de hielo purificado en diferentes presentaciones para tus reuniones, eventos y negocios.
                <br><br>
                Fabricamos hielo en cubo apto para todo tipo de bebidas y cócteles; ideal para eventos sociales, 
                fiestas, bares, restaurantes y negocios que requiera mantener sus bebidas y alimentos fríos.
            </td>
        </tr>
    </table>
    <br><br>
    <table style="width: 100%;">
        <tr>
            <td>
                <h1>Nosotros</h1>
                <br>
                Somos una empresa dedicada a la fabricación y distribución de hielo de alta calidad con presencia en el Estado de México.
                <br><br>
                Nuestro personal es altamente capacitado y utilizamos procesos estrictos de higiene y calidad 
                para garantizar la satisfacción total de nuestros clientes.
            </td>
            <td>&nbsp; &nbsp;</td>
            <td  class="clsContenidoNosotros clsBordeImgDer" >
                <div> </div>
            </td>
        </tr>
    </table>
    <br><br>
    <table style="width: 100%;">
        <tr>
            <td  class="clsContenidoFabricacion clsBordeImgIzq" >
                <div> </div>
            </td>
            <td>&nbsp; &nbsp;</td>
            <td>
                <h1>Fabricación</h1>
                <br>
                Nuestro hielo cumple con las normativas sanitarias vigentes. Elaborado con agua potable, filtrada y purificada, sin aditivos ni colorantes. 
                <br><br>                 
                El hielo se entrega en bolsas selladas y aisladas, que mantienen la temperatura y evitan la contaminación.
                <br><br>                 
                Contamos con estudio bactereológico de agua y hielo.     
            </td>
        </tr>
    </table>
    <div id="divEspacioVacioAbajo" style="height: 330px;"></div>
</div>

<div id="divBottom" class="clsBottom">
</div>
<div id="divContacto" class="clsContacto">
    <table style="width: 100%; margin: auto;">
        <tr class="clsTrContacto">
            <td style="width: 3%;"></td>
            <td class="clsImgTel"></td>
            <td>&nbsp; 55 4144 7325</td>
            <td class="clsImgMail"></td>
            <td>&nbsp;<a href="mailto:ventas@iceman.mx"> ventas@iceman.mx</a></td>
            <td class="clsImgAtencion"></td>
            <td>&nbsp;<a href="mailto:ventas@iceman.mx"> atencion@iceman.mx</a></td>
            <td></td>
            <td class="clsImgFace"></td>
            <td style="width: 3%;"></td>
        </tr>
    </table>
</div>
